import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import ConversationsList from "./conversations-list";
import {
  getConversations,
  getFilteredConversations,
} from "../../../../services/Conversations";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedConversation,
  updateUnreadCount,
} from "../../../../store/actions/conversation";
import Loader from "../../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  setContactInfo,
  setContactInfoLoading,
} from "../../../../store/actions/contactInfo";
import { useTranslation } from "react-i18next";
import UnreadCount from "../../../../components/UnreadCount";
import FilterIcon from "../../../../assets/images/filter.svg";
import ConversationFilter from "../../../../components/ConversationFilter";
import ReactDOM from "react-dom";
import Congratz from "../../../../assets/images/congratz.svg";
import Cookies from "js-cookie";
import { getRequest } from "../../../../services/Networking";
import { SETUNIPILEIDS } from "../../../../services/Networking/api";
import { timeout } from "q";
import {
  setFilterTagsRedux,
  setProspectorsListRedux,
  setLastMessageNotByProspectorRedux,
  setStartDateRedux,
  setEndDateRedux,
  setNumberOfMessagesRedux,
} from "../../../../store/actions/conversationFilter";
import { useNavigate } from "react-router-dom";
import { GETCLIENTINFO } from "../../../../services/Networking/api";

const Conversations = ({ onCollapse }) => {
  const [isUnreadConversationList, setIsUnreadConversationList] =
    useState(true);
  const [conversationList, setConversationList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);

  const [conversationNextCursor, setConversationNextCursor] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [showFilterConversation, setShowFilterConversation] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState();
  const [congrats, setCongrats] = useState(false);
  const [congratsTextType, setCongratsTextType] = useState(1);
  const [showLoader, setShowLoader] = useState(true);
  // new code
  let [loadMoreConversations, setLoadMoreConversations] = useState(true);
  const [addFilterToPayload, setAddFilterToPayload] = useState();

  // On change of filter reset, execute load conversation again
  const [filterReset, setFilterReset] = useState(false);

  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const navigate = useNavigate();

  const { t } = useTranslation();

  var params = {};

  useEffect(() => {
    Cookies.set("addFilterToPayload", false);

    if (firstRender.current) {
      loadConversation(true);
      return;
    }
    resetConversation();
    loadConversation(true);
  }, [isUnreadConversationList]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (conversationNextCursor || conversationNextCursor === undefined)
      setHasMore(true);
    else setHasMore(false);
  }, [conversationNextCursor]);

  const addElements = (elements, flag = false) => {
    // setConversationList(prevArray => {
    //   const uniqueElements = elements.filter(element =>
    //     !prevArray.some(item => deepCompare(item, element))
    //   );
    //   return [...prevArray, ...uniqueElements];
    // });

    if (flag === true) {
      setConversationList(elements);
    } else {
      setConversationList((prevArray) => {
        const uniqueElements = elements.filter(
          (element) => !prevArray.some((item) => item.id === element.id)
        );
        return [...prevArray, ...uniqueElements];
      });
    }
  };


  const loadConversation = async (
    forceClear = false,
    myFilters = null,
    myFilterCursor = null,
    mySearchText = null,
    mySearchCursor = null
  ) => {
    try {
      if (loadMoreConversations === false) {
        return;
      }
      setShowLoader(true);
      const clientId = Cookies.get("clientId") || null;
      const memberId = Cookies.get("memberId") || null;
      const isMember = Cookies.get("isMember") || null;

      if (clientId != null) {
        let res = null;
        try {
          if (isMember == 1 && memberId != null) {
            res = await getRequest(
              `${SETUNIPILEIDS}/?clientId=${clientId}&memberId=${memberId}`
            );
          } else {
            res = await getRequest(`${SETUNIPILEIDS}/?clientId=${clientId}`);
          }
        } catch (error) {
          console.error(error);
        }
        let ans = null;
        try {
          ans = await res.json();
        } catch (error) {
          console.error(error);
        }

        let response = null;
        try {
          response = await getRequest(`${GETCLIENTINFO}`);
        } catch (error) {
          console.error(error);
        }

        let res2 = null;
        try {
          res2 = await response.json();
        } catch (error) {
          console.error(error);
        }

        if (
          res2.result?.endTrialDate &&
          new Date(res2.result?.endTrialDate) < new Date()
        ) {
          navigate("/end-trial");
        }

        if (ans?.result?.cookies?.length <= 0) {
          navigate("/account-management/linkedin-settings");
        }

        if (
          res2.result?.endTrialDate &&
          new Date(res2.result?.endTrialDate) < new Date()
        ) {
          navigate("/end-trial");
        }
      }

      setIsLoadingConversation(true);
      setCongrats(false);

      let unipile_id = null;
      let parsed_unipile_id = null;
      unipile_id = Cookies.get("unipile_id") || null;
      parsed_unipile_id = JSON.parse(unipile_id) || null;
      // let parsed_unipile_id_dict = {};
      // parsed_unipile_id.forEach((key) => {
      //   parsed_unipile_id_dict[key] = null;
      // });
      params = {
        readStatus: isUnreadConversationList ? "unread" : "all",
        count: 20,
        key: 0,
        account_id: parsed_unipile_id,
        nextCursor: null,
      };

      if (conversationNextCursor && !forceClear)
        params["nextCursor"] = conversationNextCursor;

      if (searchText && !forceClear) params["messageQuery"] = searchText;

      if (mySearchText !== null && mySearchText !== "") {
        params["messageQuery"] = mySearchText;
        setSearchText(mySearchText);
        if (mySearchCursor != null) {
          params["nextCursor"] = mySearchCursor;
        }
      }

      if (myFilters !== null) {
        params = { ...params, ...myFilters };
        if (myFilterCursor != null) {
          params["nextCursor"] = myFilterCursor;
        }
      }

      let conversations = await getFilteredConversations(params); // api call
      while (
        conversations.conversations.length <= 0 &&
        Object.keys(conversations.nextCursor).length > 0
      ) {
        if (
          Object.values(conversations.nextCursor).every((value) => value === "")
          //conversations.nextCursor.every((element) => element === "")
        ) {
          if (params.readStatus == "unread") {
            setCongratsTextType(1);
            setCongrats(true);
          } else if (
            params["messageQuery"] == searchText ||
            params["messageQuery"] == mySearchText
          ) {
            setCongratsTextType(3);
            setCongrats(true);
          } else if (params.type == "FILTER") {
            setCongratsTextType(2);
            setCongrats(true);
          }
          // setLoadMoreConversations(false);
          setShowLoader(false);
          return;
        }
        // params.nextCursor = conversations?.nextCursor;
        params.nextCursor = conversations?.nextCursor;
        conversations = await getFilteredConversations(params);
      }

      setShowLoader(false);

      if (!forceClear) {
        addElements(conversations?.conversations);
        // setConversationList([
        //   ...conversationList,
        //   ...conversations?.conversations,
        // ]);

        if (isUnreadConversationList)
          dispatch(
            updateUnreadCount(
              conversationList?.length + conversations?.conversations?.length
            )
          );
      } else {
        // setConversationList(conversations?.conversations);
        addElements(conversations?.conversations);
        if (isUnreadConversationList)
          dispatch(updateUnreadCount(conversations?.conversations?.length));
      }
      setConversationNextCursor(conversations?.nextCursor);
      setIsLoadingConversation(false);

      if (
        Object.values(conversations.nextCursor).every((value) => value === "")
        //conversations.nextCursor.every((element) => element === "")
      ) {
        // setLoadMoreConversations(false);
        // params["nextCursor"] = Array(parsed_unipile_id.length).fill("");
        let parsed_unipile_id_dict = {};
        parsed_unipile_id.forEach((key) => {
          parsed_unipile_id_dict[key] = "";
        });
        params["nextCursor"] = parsed_unipile_id_dict;
        setShowLoader(false);
        return;
      }
    } catch (error) {
      console.log("Error in loading conversations", error);
    }
  };

  const onCoversationSelect = (conversation) => {
    dispatch(setContactInfo({}));
    dispatch(setContactInfoLoading(true));
    dispatch(setSelectedConversation(conversation));

    onCollapse("left");
  };

  const searchConversation = async () => {
    setLoadMoreConversations(true);
    setCongrats(false);
    setShowLoader(true);
    setCongratsTextType(3);

    var unipile_id = null;
    var parsed_unipile_id = null;
    unipile_id = Cookies.get("unipile_id") || null;
    parsed_unipile_id = JSON.parse(unipile_id) || null;

    resetConversation();

    setShowLoader(true);

    setIsLoadingConversation(true);

    const conversations = await getFilteredConversations({
      readStatus: isUnreadConversationList ? "unread" : "all",
      count: 20,
      key: 0,
      messageQuery: searchText,
      account_id: parsed_unipile_id,
      // nextCursor: Array(parsed_unipile_id.length).fill(null),
      nextCursor: null,
    });

    if (
      conversations?.conversations?.length <= 0 &&
      Object.keys(conversations.nextCursor).length > 0
    ) {
      if (
        Object.values(conversations.nextCursor).every((value) => value === "")
      ) {
        setCongrats(true);
        setCongratsTextType(3);
        return;
      } else {
        setLoadMoreConversations(true);
        setSearchText(searchText);
        setHasMore(true);
        Cookies.set("addFilterToPayload", true);
        setAddFilterToPayload(true);
        loadConversation(
          true,
          null,
          null,
          searchText,
          conversations.nextCursor
        );
        return;
      }
    }

    // setConversationList(conversations?.conversations);
    addElements(conversations?.conversations);
    setConversationNextCursor(conversations?.nextCursor);
    setSearchText(searchText);
    setIsLoadingConversation(false);
    setShowLoader(false);
    return;
  };

  const clearSearch = () => {
    setAddFilterToPayload(false);
    setLoadMoreConversations(true);
    setIsSearchResult(false)

    resetConversation();
    loadConversation(true);
  };

  const resetConversation = () => {
    setSelectedFilters(null);
    setLoadMoreConversations(true);
    setConversationList([]);
    setConversationNextCursor(undefined);
    setSearchText("");
  };
  useEffect(() => {
    setIsSearchResult(false);
  }, [searchText]);

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      setLoadMoreConversations(true);
      setSearchText(searchText);
      setIsSearchResult(true);
      searchConversation();
    }
  };

  const renderFilterIcon = () => {
    return (
      <div style={{ display: "flex" }}>
        <img
          style={{ marginTop: 20, marginLeft: 10, cursor: "pointer" }}
          src={FilterIcon}
          onClick={onFilterClick}
        />
      </div>
    );
  };

  const onFilterClick = () => {
    setShowFilterConversation(true);
  };

  const onFilterConversationClose = () => {
    setShowFilterConversation(false);
  };

  const handleConversationsFromFilter = (
    filteredConversations,
    filterPayload
  ) => {
    setCongratsTextType(2);
    // setConversationList(filteredConversations);
    addElements(filteredConversations, true);
    setSelectedFilters(filterPayload);
    setConversationNextCursor(filterPayload.nextCursor);
    setAddFilterToPayload(true);

    if (!filteredConversations.length) {
      setHasMore(false);
    }
    setShowFilterConversation(false);
    setShowLoader(true);

    if (
      filteredConversations.length <= 0 &&
      Object.keys(filterPayload.nextCursor).length > 0
    ) {
      if (
        Object.values(filterPayload.nextCursor).every((value) => value === "")
      ) {
        setCongratsTextType(2);
        setCongrats(true);
        return;
      } else {
        setLoadMoreConversations(true);
        setHasMore(true);
        Cookies.set("addFilterToPayload", true);
        setAddFilterToPayload(true);
        loadConversation(true, filterPayload, filterPayload.nextCursor);
        return;
      }
    }
    if (filteredConversations.length <= 5) {
      setShowLoader(false);
      setHasMore(true);
      setCongrats(false);
      return;
    }
  };
  return (
    <div id="conversations-container">
      <div id="conversations-header">
        <h1>{t("MESSAGES")}</h1>
        <div id="conversations-unread-filter-container">
          <button
            id="unread-conversations"
            className={isUnreadConversationList ? "selected" : ""}
            onClick={() => {
              setSelectedFilters(null);
              setAddFilterToPayload(false);
              setLoadMoreConversations(true);
              setIsUnreadConversationList(true);
              resetConversation();
            }}
          >
            <div>
              {t("UNREAD")}
              {(!isLoadingConversation && isUnreadConversationList) ||
              !isUnreadConversationList ? (
                <UnreadCount />
              ) : (
                <UnreadCount />
              )}
            </div>
            <div className="selectedBar"></div>
          </button>
          <button
            id="all-conversations"
            className={!isUnreadConversationList ? "selected" : ""}
            onClick={() => {
              setSelectedFilters(null);
              setAddFilterToPayload(false);
              setLoadMoreConversations(true);
              setIsUnreadConversationList(false);
              resetConversation();
            }}
          >
            {t("ALL")}

            <div className="selectedBar"></div>
          </button>
        </div>
        {!isUnreadConversationList && (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }} id="search-conversations-container">
              <input
                id="search-conversations-input"
                type="text"
                placeholder={t("SEARCH")}
                value={searchText}
                onKeyDown={handleSearchKeyDown}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {!isSearchResult ? (
                <button
                  className="search-btn"
                  id="search-conversations"
                  onClick={() => {
                    setLoadMoreConversations(true);
                    setCongrats(false);
                    setShowLoader(true);
                    setIsSearchResult(true);
                    searchConversation();
                  }}
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              ) : (
                <button
                  className="search-btn"
                  onClick={() => {
                    setLoadMoreConversations(true);
                    setCongrats(false);
                    setShowLoader(true);
                    setIsSearchResult(false)
                    clearSearch();
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              )}
            </div>
            {/* Filter chat icon */}
            {renderFilterIcon()}
          </div>
        )}
      </div>
      {
        <InfiniteScroll
          height={
            isUnreadConversationList
              ? "calc(100% - 60px)"
              : "calc(100% - 125px)"
          }
          dataLength={conversationList.length}
          next={() => {
            loadConversation(
              false,
              selectedFilters,
              conversationNextCursor,
              searchText,
              conversationNextCursor
            );
          }}
          hasMore={congrats == true ? false : true}
          loader={showLoader == true && <Loader />}
          endMessage={
            conversationList.length === 0 && (
              <div class="congratz_center">
                <div class="nothing">
                  <img src={Congratz} width={120} />
                </div>

                {congratsTextType == 1 ? (
                  <div class="nothing">{t("NO_CONVERSATION")}</div>
                ) : congratsTextType == 2 ? (
                  <div class="nothing">{t("NO_CONVERSATION_FILTER")}</div>
                ) : (
                  congratsTextType == 3 && (
                    <div class="nothing">{t("NO_CONVERSATION_SEARCH")}</div>
                  )
                )}
              </div>
            )
          }
        >
          <ConversationsList
            conversationList={conversationList}
            setSelectedConversation={onCoversationSelect}
          />
        </InfiniteScroll>
      }
      <div className="opacity-container"></div>
      {showFilterConversation &&
        ReactDOM.createPortal(
          <ConversationFilter
            filters={selectedFilters}
            onClose={onFilterConversationClose}
            onFilterConversationsResult={handleConversationsFromFilter}
            setFilterReset={() => {
              setLoadMoreConversations(true);
              setSelectedFilters(null);
              Cookies.set("addFilterToPayload", false);
              clearSearch();
            }}
            setAddFilterToPayload={setAddFilterToPayload}
          />,
          document.body
        )}
    </div>
  );
};

export default Conversations;
