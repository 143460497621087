const getUrlParamsString = (params) => {
  const queryArr = Object.keys(params).map((key) => `${key}=${params[key]}`);
  return queryArr.join("&");
};
const formattedUnixTime = (_unixTime) => {
  const ONE_SECOND = 1000;
  const ONE_MINUTE = 60 * ONE_SECOND;
  const ONE_HOUR = 60 * ONE_MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_WEEK = 7 * ONE_DAY;

  let unixTime = _unixTime;
  let unixNow = Date.now(); // now, in milliseconds
  let now = new Date(unixNow);
  let time = new Date(unixTime);
  let yesterday = new Date(unixNow - ONE_DAY);
  let deltaTime = unixNow - unixTime;

  let months = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aoû",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];
  let days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
  let year = time.getFullYear();
  let month = months[time.getMonth()];
  let date = time.getDate();
  let day = days[time.getDay()];
  let hour = time.getHours();
  let min = time.getMinutes();

  if (deltaTime < ONE_SECOND) {
    return "Maintenant";
  } else if (deltaTime < ONE_MINUTE) {
    return Math.floor(deltaTime / ONE_SECOND) + "s";
  } else if (deltaTime < ONE_HOUR) {
    return Math.floor(deltaTime / ONE_MINUTE) + "m";
  } else if (time.setHours(0, 0, 0, 0) == now.setHours(0, 0, 0, 0)) {
    return Math.floor(deltaTime / ONE_HOUR) + "h";
  } else if (time.setHours(0, 0, 0, 0) == yesterday.setHours(0, 0, 0, 0)) {
    if (min < 10) min = "0" + min;
    return "Hier, " + hour + ":" + min;
  } else if (deltaTime < ONE_WEEK) {
    return day;
  } else if (year == now.getFullYear()) {
    return date + " " + month;
  } else {
    return date + " " + month + " " + year;
  }
};

const isValidTimeDiff = (_unixTime, minDiff = 3600000) => {
  let unixTime = _unixTime;
  let unixNow = Date.now(); // now, in milliseconds
  let deltaTime = unixNow - unixTime;

  if (deltaTime < minDiff) {
    return true;
  }

  return false;
};

const stringToColour = (str) => {
  if (str == "Interested") {
    return "#069852";
  }
  if (str == "Not Interested") {
    return "#FF5454";
  }

  if (str == "Reminder") {
    return "#FF910F";
  }

  let hash = 0;
  str?.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  const hue = hash % 360;
  const saturation = 30 + Math.abs(hash % 40); // Adjust the range for pastel saturation
  const lightness = 40 + Math.abs(hash % 20); // Adjust the range for pastel lightness

  const colour = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  return colour;
};

// const stringToColour = (str) => {
//   let str2 = str.toUpperCase()
//   let hash = 0;
//   str?.split("").forEach((char) => {
//     hash = char.charCodeAt(0) + ((hash << 5) - hash);
//   });
//   let colour = "#";
//   for (let i = 0; i < 3; i++) {
//     const value = (hash >> (i * 8)) & 0xff;
//     colour += value.toString(16).padStart(2, "0");
//   }
//   return colour;
// };
const formatDateFromMillis = (millis) => {
  const date = new Date(millis);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const insertUrlParam = (key, value) => {
  // if (history.pushState) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
  // }
};
export {
  getUrlParamsString,
  formattedUnixTime,
  stringToColour,
  isValidTimeDiff,
  formatDateFromMillis,
  insertUrlParam,
};