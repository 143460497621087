const BASE_URL = "https://chat.mirrorprofiles.com:8001";
//const BASE_URL = "http://localhost:8001";
//const BASE_URL = "https://chat.mirrorprofiles.com:8032";
const VERSION = "/v2"
const VERSION2 = "/unipile"
const VERSION3 = "/member"
// const BASE_URL = "http://localhost:8003/api";
// "http://localhost:8001/unipile/getAllChats/zv_mhlVvRraj8S5zmgDGlw"


const CONVERSATION2 = BASE_URL + VERSION2 + "/getChatMessages";
const CONVERSATIONS2 = BASE_URL + VERSION2 + "/getAllChats";
const ACCOUNT_INFO = BASE_URL + VERSION2 + "/getAccountInfo";
const ACCOUNT_INFO_OFFLINE = BASE_URL + VERSION2 + "/getAccountInfoOffline";
const FILTER_CONVERSATIONS2 = BASE_URL + VERSION2 + "/getAllFilteredChats";
const SEND_MESSAGE2 = BASE_URL + VERSION2 + "/sendMessage";
const HOSTEDAUTHURL = BASE_URL + VERSION2 + "/getHostedAuthUrl";
const SETUNIPILEIDS = BASE_URL + VERSION2 + "/setUnipileIds";
const GETCLIENTINFO = BASE_URL + VERSION2 + "/getClientInfo";
const CLIENT_PROSPECTORS = BASE_URL + VERSION2 + "/getClientProspectors";
const MODIFY_CLIENT = BASE_URL + VERSION2 + "/modifyClient";
const MODIFY_PROSPECTOR = BASE_URL + VERSION2 + "/modifyProspector";
const REMOVE_ACCOUNT = BASE_URL + VERSION2 + "/removeAccount";
const GET_PROSPECTOR_URN_FOR_FILTERING = BASE_URL + VERSION2 + "/getProspectorsForFiltering";
const GET_PROSPECTOR_TAGS_FOR_FILERTING = BASE_URL + VERSION2 + "/getProspectorsTagsForFiltering";
const MARK_UNREAD = BASE_URL + VERSION2 + "/markConversationAsUnread";
const EDIT_TAG = BASE_URL + VERSION2 + "/tags/user/editTag";
const RESTART_ACCOUNT = BASE_URL + VERSION2 + "/restartAccount";
const GET_ACCOUNT_STATUS = BASE_URL + VERSION2 + "/getAccountStatus";
const SET_VALID_SESSION = BASE_URL + VERSION2 + "/setValidSession";

const FIND_ALL_MEMBERS = BASE_URL + VERSION3 + "/findAll";
const INVITE_MEMBER = BASE_URL + VERSION3 + "/invite";
const DELETE_MEMBER = BASE_URL + VERSION3 + "/delete";
const FIND_MEMBER = BASE_URL + VERSION3 + "/find";
const MODIFY_DISALLOWED_PROSPECTORS = BASE_URL + VERSION3 + "/modify";
const GET_MEMBER_PROSPECTORS = BASE_URL + VERSION3 + "/getProspectors";

const CONVERSATIONS = BASE_URL + VERSION + "/conversations";
const CONTACT_INFO = BASE_URL + VERSION + "/contactInfos";
const CONVERSATION = BASE_URL + VERSION + "/conversation";
const SEND_MESSAGE = BASE_URL + VERSION + "/sendMessage";
const QUICK_REPLIES = BASE_URL + VERSION + "/quickReplies";
const CANNED_RESPONSES = BASE_URL + VERSION + "/cannedResponses";
const SET_CANNED_RESPONSES = BASE_URL + VERSION + "/setCannedResponse";
const DELETE_CANNED_RESPONSES = BASE_URL + VERSION + "/deleteCannedResponse";
const TRIGGER_WEBHOOK = BASE_URL + VERSION + "/triggerWebhook";
const SAVE_OR_UPDATE_WEBHOOK = BASE_URL + VERSION + "/webhook/save";
const TRIGGER_WEBHOOK_BY_ID = BASE_URL + VERSION + "/webhook/triggerById";
const GET_WEBHOOK_URLS = BASE_URL + VERSION + "/webhookUrls";
const GET_STATUS_MESSAGE = BASE_URL + VERSION + "/statusMessage";
const GET_CLIENT_PROSPECTORS = BASE_URL + VERSION + "/client/prospectors";
const CUSTOM_VARIABLE_VALUE = BASE_URL + VERSION + "/cannedResponses/customVariable";
const VALID_CUSTOM_SESSION = BASE_URL + VERSION + "/cannedResponses/customSession";

const LOGIN = BASE_URL+"/login"
const LOGOUT = BASE_URL+"/logout"
const SESSION_CHECK = BASE_URL+"/sessionCheck"
const FORGOT_PASSWORD = BASE_URL+"/forgotPassword"
const CHANGE_PASSWORD = BASE_URL+"/changePassword"

const GET_CLIENT_TAGS = BASE_URL+VERSION+"/tags"
const PROSPECTOR_USER_TAGS = BASE_URL+VERSION+"/tags/user"

const INTERLOCUTOR_INFOS = BASE_URL+VERSION+"/interlocutors"

const BLOCK_ACCOUNT = BASE_URL + VERSION + "/block/account";

const UPDATE_MESSAGE_URL = BASE_URL+ VERSION +"/updateMessage";
const DELETE_MESSAGE_URL = BASE_URL+ VERSION +"/delete/message";
const CONVERSATION_PREFERENCES = BASE_URL + VERSION + "/conversation/preferences";

const SET_CUSTOM_REMINDER_DATE = BASE_URL + VERSION + "/conversation/setCustomReminderDate";

const FILTER_CONVERSATIONS = BASE_URL + VERSION + "/conversations/filter"
export {
  CONVERSATIONS2,
  CONVERSATION2,
  ACCOUNT_INFO,
  ACCOUNT_INFO_OFFLINE,
  FILTER_CONVERSATIONS2,
  SEND_MESSAGE2,
  HOSTEDAUTHURL,
  SETUNIPILEIDS,
  GETCLIENTINFO,
  CLIENT_PROSPECTORS,
  MODIFY_CLIENT,
  MODIFY_PROSPECTOR,
  REMOVE_ACCOUNT,
  GET_PROSPECTOR_TAGS_FOR_FILERTING,
  GET_PROSPECTOR_URN_FOR_FILTERING,
  EDIT_TAG,
  RESTART_ACCOUNT,
  SET_VALID_SESSION,
  GET_ACCOUNT_STATUS,

  FIND_ALL_MEMBERS,
  INVITE_MEMBER,
  DELETE_MEMBER,
  FIND_MEMBER,
  MODIFY_DISALLOWED_PROSPECTORS,
  GET_MEMBER_PROSPECTORS,

  INTERLOCUTOR_INFOS,

  CONVERSATIONS,
  CONTACT_INFO,
  CONVERSATION,
  QUICK_REPLIES,
  CANNED_RESPONSES,
  SET_CANNED_RESPONSES,
  DELETE_CANNED_RESPONSES,
  SEND_MESSAGE,
  MARK_UNREAD,
  TRIGGER_WEBHOOK,
  GET_WEBHOOK_URLS,
  LOGIN,
  LOGOUT,
  SESSION_CHECK,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  GET_STATUS_MESSAGE,
  GET_CLIENT_TAGS,
  PROSPECTOR_USER_TAGS,
  BLOCK_ACCOUNT,
  UPDATE_MESSAGE_URL,
  CONVERSATION_PREFERENCES,

  SET_CUSTOM_REMINDER_DATE,


  DELETE_MESSAGE_URL,
  SAVE_OR_UPDATE_WEBHOOK,
  TRIGGER_WEBHOOK_BY_ID,
  GET_CLIENT_PROSPECTORS,
  FILTER_CONVERSATIONS,
  CUSTOM_VARIABLE_VALUE,
  VALID_CUSTOM_SESSION
};
