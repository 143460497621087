import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  formattedUnixTime,
  isValidTimeDiff,
} from "../../../../../services/Utils";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedMessage,
  deleteMessage as deleteMessageFromStore,
} from "../../../../../store/actions/message";
import ExpandDots from "../../../../../assets/images/expand_dots.svg";
import ModifyMessage from "../../../../../assets/images/modify_message.svg";
import DeleteMessage from "../../../../../assets/images/delete.svg";
import { useTranslation } from "react-i18next";
import DefaultPP from "../../../../../assets/images/mirrorprofiles-default-profile-picture.png";
import { deleteMessage } from "../../../../../services/Conversation";
import EditMessageBox from "./EditMessageBox";
import { toast } from 'react-toastify'

const Message = ({ message }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = useSelector((state) => state);

  const [expandedAction, setExpandedActions] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const onMessageEdit = () => {
    setExpandedActions(false);
    setIsEditMode(true)
    dispatch(setSelectedMessage(message));
  };

  const onMessageDelete = async () => {
    setExpandedActions(false);
    const body = {
      key: "0",
      messageUrn: message?.entityUrn,
      prospectorUrn:
        state?.conversation?.selectedConversation?.prospector?.entityUrn,
    };
    const msgDeleted = await deleteMessage(body);
    toast.success(t("DELETE_SUCCESS"), {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(deleteMessageFromStore(message?.entityUrn));
  };

  const renderExpandedMessageActions = () => {
    return (
      <div className="mesage-actions">
        <div className="message-input-wrapper" onClick={onMessageEdit}>
          <img src={ModifyMessage} />
          {t("MODIFY")}
        </div>
        <div
          className="message-input-wrapper"
          style={{ color: "#ED2939" }}
          onClick={onMessageDelete}
        >
          <img src={DeleteMessage} />
          {t("DELETE")}
        </div>
      </div>
    );
  };
  const onEditCancel = () => {
    setIsEditMode(false)
  }
  const onEditMessageConfirm = () => {
    setIsEditMode(false)
  }
  return (
    <div
      className={`message ${
        message?.author?.isProspector ? "prospector" : ""
      } ${message?.isPending ? "semi-transparent" : ""}`}
      style={{ background: isEditMode ? "rgba(34, 135, 243, .2)" : "" }}
    >
      <div class="message-left">
        <a href={message?.author?.profilePicture} target="_blank">
          <img
            className="author-icon"
            src={message?.author?.profilePicture || DefaultPP}
          />
        </a>
      </div>
      <div class="message-right" style={{ flex: isEditMode ? 1 : "" }}>
        {isEditMode ? (
          <div className="edit-message-wrapper">
            <EditMessageBox message={message} onEditMessageConfirm={onEditMessageConfirm} onEditCancel={onEditCancel}/>
            
          </div>
        ) : (
          <span className="message-date-wrapper">
            <pre class="message-content" style={{ position: "relative" }}>
              {message?.body}
              {message?.author?.isProspector &&
              isValidTimeDiff(message?.deliveredAt) ? (
                // <img
                //   src={ExpandDots}
                //   onClick={() => setExpandedActions(!expandedAction)}
                //   className="expand-option"
                // />
                ""
              ) : (
                ""
              )}
              {expandedAction && renderExpandedMessageActions()}
            </pre>
            <p className="message-at">
              {formattedUnixTime(message?.deliveredAt)}
            </p>
          </span>
        )}
      </div>
    </div>
  );
};

export default Message;
