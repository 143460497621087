import { useState, useEffect, useDebugValue } from "react";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import React from "react";

import StyledBox from "../../../components/StyledBox";
import TextChip from "../../../components/TextChip";
import Button from "../../../components/Button";
import GradientButton from "../../../components/GradientButton";
import InputField from "../../../components/InputField";
import DropdownBox from "../../../components/DropdownBox";
import Modal from "../../../components/Modal";
import List from "../../../components/List";
import MPCheckbox from "../../../components/MPFactory/MPCheckbox";
import Cookies from "js-cookie";


import MenuDotsIcon from "../../../assets/images/menu_dots.svg";
import editIcon from "../../../assets/images/edit_icon_16x16.svg";
import trashIcon from "../../../assets/images/trash_icon.svg";
import demoDp from "../../../assets/images/demo_dp.jpeg";

import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../../services/Networking";
import {
  FIND_ALL_MEMBERS,
  INVITE_MEMBER,
  DELETE_MEMBER,
  CONVERSATION_PREFERENCES,
  CLIENT_PROSPECTORS,
  FIND_MEMBER,
  MODIFY_DISALLOWED_PROSPECTORS,
} from "../../../services/Networking/api";
import { toast } from "react-toastify";

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const clientId = Cookies.get("clientId") || null;
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();

    useEffect(() => {
        let isMember = Cookies.get("isMember") === '1' || null || '0' || undefined;
        if (isMember === true || isMember === "1" || isMember === 1) {
          isMember = true;
        } else {
          isMember = false;
        }
        if (isMember) {
            navigate("/account-management/linkedin-settings");
        }
    }, []);

  const fetchData = async () => {
    try {
      const members = await getRequest(`${FIND_ALL_MEMBERS}/${clientId}`);
      const res = await members.json();
      setTeamMembers(res?.result);
      console.log("TEAM MEMBERS: ", res?.result)
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="xl:w-[70%] lg:w-[85%] w-[90%] mx-auto mt-[2rem] pb-10">
      <h1 className="text-[32px] font-bold">Votre équipe</h1>
      <StyledBox>
        <div className="w-full flex flex-col items-center gap-10">
          <TeamInformation>
            {teamMembers.map((member, index) => (
              <TeamIndividual
                key={index}
                name={member?.email}
                individualType={member?.status ? "Member" : "Invite Sent"}
                index={member?.id}
                id={member?.id}
                email={member?.email}
              />
            ))}
          </TeamInformation>
          <hr className="w-full border-t border-[#ffffff]" />
          <Invite />
        </div>
      </StyledBox>
    </div>
  );
}

const TeamInformation = ({ children }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleDropdown = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Close dropdown if clicked again
    } else {
      setExpandedIndex(index);
    }
  };

    return (
        <div className="w-full">
            <h1 className="font-bold text-2xl mt-0 mb-2">Membres</h1>
            <div className="flex flex-col items-center gap-7 min-h-[6.5rem] max-h-[16rem] sm:max-h-[12.5rem] overflow-y-auto overflow-x-hidden">
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { index, toggleDropdown, expandedIndex });
                })}
            </div>
        </div>
    )
}

const TeamIndividual = ({
  name,
  individualType,
  index,
  toggleDropdown,
  expandedIndex,
  id,
  email,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [prospectorsList, setProspectorsList] = useState([]);
  const [apiFlag, setApiFlag] = useState(false);
  const [disallowedProspectors, setDisallowedProspectors] = useState([]);
  const [checkBoxesStatuses, setCheckBoxesStatuses] = useState({});

  const handleModify = async () => {
    try {
      console.log("CHECKBOXES STATUS: ", checkBoxesStatuses);
      const disallowedProspectors = Object.keys(checkBoxesStatuses).filter(
        (key) => !checkBoxesStatuses[key]
      );

      const intsArray = disallowedProspectors.map(str => Number(str));


      const params = {
        email,
        disallowedProspectors: intsArray,
      };

      setModalOpen(false);

      const res = await postRequest(
        MODIFY_DISALLOWED_PROSPECTORS,
        params
      );

      setApiFlag((curr) => !curr);  
    } catch (error) {
      console.error("Error modifying prospectors:", error.message);
    }
  };

  const handleDelete = async () => {
    try {
      const params = { email: email };
      const result = await deleteRequest(`${DELETE_MEMBER}`, params);
      const res = await result.json();
      if (res?.code === 200) {
        //re render based on flag
        setApiFlag((curr) => !curr);
      } else {
        toast.error("COULD NOT DELETE MEMBER", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          // window.location.reload();
        }, 1500);
      }
    } catch (error) {
      toast.error("COULD NOT DELETE MEMBER", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        // window.location.reload();
      }, 1500);
    }
  };

  //make api call here
  const fetchData = async () => {
    try {
      const response = await getRequest(`${CLIENT_PROSPECTORS}`);
      const res = await response.json();

      const prospectorsData = res.result.prospectors.map((item) => ({
        id: item.id,
        fullName: item.full_name,
        validSession: item.valid_session,
        profilePicture: item.profilePicture,
        unipileId: item.unipile_id,
      }));

      // setProspectorsList(prospectorsData.filter((item) => item.validSession));
      setProspectorsList(prospectorsData);

      // const memberId = Cookies.get("memberId") || null;
      const response2 = await getRequest(`${FIND_MEMBER}/${email}`);
      const res2 = await response2.json();

      setDisallowedProspectors(res2?.result?.disallowedProspectors);

      prospectorsList.map((prospector) => {
        if (disallowedProspectors.includes(prospector.id)) {
          setCheckBoxesStatuses((curr) => ({
            ...curr,
            [prospector.id]: false,
          }));
        } else {
          setCheckBoxesStatuses((curr) => ({
            ...curr,
            [prospector.id]: true,
          }));
        }
      });
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
      setProspectorsList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiFlag]);

  return (
    <>
      <MediaQuery minWidth={640}>
        <div className="w-full flex flex-row items-center justify-between">
          <h2 className="text-[#0A66C2] text-[16px] font-bold m-0"> {name} </h2>
          <div className="min-w-[16%] flex flex-row items-center justify-between gap-2">
            <TextChip text={individualType} disabled={false} />
            <TeamMenu
              index={index}
              setModalOpen={setModalOpen}
              toggleDropdown={toggleDropdown}
              expandedIndex={expandedIndex}
              id={id}
              email={email}
              setApiFlag={setApiFlag}
            />
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={639}>
        <div className="w-full flex flex-col items-center gap-2">
          <div className="w-full flex flex-row items-center justify-between">
            <h2 className="text-[#0A66C2] text-[16px] font-bold m-0">
              {" "}
              {name}{" "}
            </h2>
            <TeamMenu
              index={index}
              setModalOpen={setModalOpen}
              toggleDropdown={toggleDropdown}
              expandedIndex={expandedIndex}
              id={id}
              email={email}
            />
          </div>
          <TextChip text={individualType} disabled={false} />
        </div>
      </MediaQuery>

      {modalOpen && (
        <Modal setModalOpen={setModalOpen}>
          <div className="px-4 flex flex-col items-center text-center gap-5">
            <div>
              <h1 className="text-[24px] mt-0">
                Choisissez les comptes auquel name peut accéder
              </h1>
              <span className="text-[16px]">
                L'utilisateur n'aura accès uniquement qu'aux messages de ces
                profils Linkedin
              </span>
            </div>
            <div className="w-full">
              <List>
                {prospectorsList.map((prospector, index) => (
                  <>
                    <TeamListItem
                      key={index}
                      profilePicture={prospector.profilePicture}
                      fullName={prospector.fullName}
                      checkValue={
                        !disallowedProspectors.includes(prospector.id)
                      }
                      memberId={prospector.id}
                      setCheckBoxesStatuses={setCheckBoxesStatuses}
                    />
                    <hr className="w-[90%] border-t my-2 border-[#ffffff]" />
                  </>
                ))}
              </List>
            </div>
            <div className="flex justify-between items-center gap-5 mt-5">
              {/* <div className="w-1/2">
                <Button color="#FA5D72" text="Delete" onClick={handleDelete}/>
              </div> */}
              <div className="w-1/2">
                <GradientButton text="Modify" onClick={handleModify} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const TeamMenu = ({
  id,
  index,
  toggleDropdown,
  expandedIndex,
  setModalOpen,
  email,
  setApiFlag,
}) => {
  const [expanded, setExpanded] = useState(false);

  const deleteMember = async (email) => {
    try {
      const params = { email: email };
      const result = await deleteRequest(`${DELETE_MEMBER}`, params);
      const res = await result.json();
      if (res?.code === 200) {
        //re render based on flag
        window.location.reload();
      } else {
        toast.error("COULD NOT DELETE MEMBER", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          // window.location.reload();
        }, 1500);
      }
    } catch (error) {
      toast.error("COULD NOT DELETE MEMBER", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        // window.location.reload();
      }, 1500);
    }
  };

  useEffect(() => {
    setExpanded(index === expandedIndex);
  }, [index, expandedIndex]);

  return (
    <div className="relative h-[24px]">
      <img
        className="cursor-pointer"
        src={MenuDotsIcon}
        alt="menu dots"
        onClick={() => toggleDropdown(index)}
      />
      {expanded && (
        <DropdownBox right="0px" top="30px" width="7.5rem">
          <div
            onClick={() => {
              setModalOpen((curr) => !curr);
              setApiFlag((curr) => !curr);
            }}
            className="flex items-center cursor-pointer"
          >
            <img alt="Edit" src={editIcon} className="mx-2" />
            <span className="text-[10px] text-[#1E93FD] font-bold">Modify</span>
          </div>
          <hr className="w-full border-t my-2 border-[#ffffff]" />
          <div
            className="flex items-center cursor-pointer"
            onClick={async () => {
              await deleteMember(email);
              toggleDropdown(index);
            }}
          >
            <img alt="Delete" src={trashIcon} className="mx-2" />
            <span className="text-[10px] text-[#ED2939] font-bold">Delete</span>
          </div>
        </DropdownBox>
      )}
    </div>
  );
};

const Invite = () => {
  const [emailValue, setEmailValue] = useState("");

  const sendInvite = async (emailValue) => {
    const params = {
      email: emailValue,
      clientId: Cookies.get("clientId") || null,
    };
    try {
      const result = await postRequest(INVITE_MEMBER, params);
      const res = await result.json();
      if (res?.code === 200) {
        toast.success("INVITE SENT", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("COULD NOT SEND INVITE", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          // window.location.reload();
        }, 1500);
      }
    } catch (error) {
      toast.error("COULD NOT SEND INVITE", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        // window.location.reload();
      }, 1500);
    }
  };

  return (
    <div className="w-[100%] flex flex-col sm:flex-row items-center justify-between gap-10">
      <InputField
        placeholder="example@example.com"
        setEmailValue={setEmailValue}
        emailValue={emailValue}
      />
      <div className="w-full sm:w-[30%]">
        <GradientButton text="Invite" onClick={() => sendInvite(emailValue)} />
      </div>
    </div>
  );
};

const TeamListItem = ({
  profilePicture,
  fullName,
  checkValue = false,
  memberId,
  setCheckBoxesStatuses,
}) => {
  const [check, setCheck] = useState(checkValue);

  return (
    <div className="flex items-center justify-between w-[90%]">
      <div className="flex items-center gap-3">
        <img
          src={profilePicture}
          alt="Profile"
          className="w-[50px] rounded-full"
        />
        <h4 className="text-[#0A66C2]">{fullName}</h4>
      </div>
      <div className="p-0 m-0">
        <MPCheckbox
          value={check}
          setValue={setCheck}
          memberId={memberId}
          setCheckBoxesStatuses={setCheckBoxesStatuses}
        />
      </div>
    </div>
  );
};

export default Team;
